import { slug } from '@/services/string'

export default {
  data() {
    return {
      inputCategoryUrl: {
        urlToAcceptChanged: false,
        urlToAccept: '',
        current: '',
        exclude: '/',
        inputUrlName: 'self_url',
        inputParentName: 'meu_meu_id',
      },
    }
  },
  methods: {
    handleIsReservedPage(key, name) {
      this.$nextTick(() => {
        if (this.form.getFieldValue(name)) {
          switch (key) {
          case 1:
            this.inputCategoryUrl.urlToAccept = ''
            this.form.setFieldsValue({
              self_url: '',
            })
            break
          case 2:
            this.inputCategoryUrl.urlToAccept = 'produkty'
            this.form.setFieldsValue({
              self_url: 'produkty',
            })
            break
          case 3:
            this.inputCategoryUrl.urlToAccept = 'blogy'
            this.form.setFieldsValue({
              self_url: 'blogy',
            })
            break
          }
          this.inputCategoryUrl.urlToAcceptChanged = true
        }
      })
    },
    handleChangeUrl(checkParent = true) {
      this.$nextTick(() => {
        if (this.form.getFieldValue(this.inputCategoryUrl.inputUrlName) !== undefined && typeof this.form.getFieldValue(this.inputCategoryUrl.inputUrlName) === 'string') {
          this.form.setFieldsValue({
            [this.inputCategoryUrl.inputUrlName]: slug(this.form.getFieldValue(this.inputCategoryUrl.inputUrlName), '-', this.inputCategoryUrl.exclude),
          })
        }
        this.changedParentMenu(false)
      })
    },
    changedParentMenu(trackChanged = true) {
      if (trackChanged) {
        this.inputCategoryUrl.urlToAcceptChanged = false
      }
      this.$nextTick(() => {
        try {
          const currentSelectedParent = this.form.getFieldValue(this.inputCategoryUrl.inputParentName) ? Number.parseInt(this.form.getFieldValue(this.inputCategoryUrl.inputParentName)) : undefined
          const selectedParentUrl = currentSelectedParent ? this.parentOptions.get(currentSelectedParent) : false
          const newUrl = selectedParentUrl ? (selectedParentUrl + '/' + this.form.getFieldValue(this.inputCategoryUrl.inputUrlName)) : this.form.getFieldValue(this.inputCategoryUrl.inputUrlName)
          if (trackChanged) {
            this.inputCategoryUrl.urlToAcceptChanged = newUrl !== this.inputCategoryUrl.urlToAccept
          }
          this.inputCategoryUrl.urlToAccept = newUrl
        } catch (e) {
        }
      })
    },
  },
}
